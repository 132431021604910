<script lang="ts">
	import { fade } from 'svelte/transition'

	export let is_open = true
	let dialog: HTMLDialogElement

	$: if (dialog) {
		if (is_open && !dialog.open) {
			dialog.showModal()
		} else if (!is_open && dialog.open) {
			dialog.close()
		}
	}

	function handle_close() {
		is_open = false
	}
</script>

<svelte:head>
	{#if is_open}
		<!--
			Prevent interaction with the background content when the modal is open:
		-->
		<style>
			body {
				overflow: hidden;
				position: fixed;
				width: 100%;
			}
		</style>
	{/if}
</svelte:head>

<dialog
	bind:this={dialog}
	on:close={handle_close}
	class="fixed inset-0 z-30 h-[100dvh] overflow-hidden bg-transparent p-0"
	aria-labelledby="modal-title"
	aria-describedby="modal-description"
	aria-modal="true"
>
	<div transition:fade|global={{ duration: 150 }} class="grid h-full w-full place-items-center">
		<button
			type="button"
			class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			on:click={handle_close}
			on:keydown={(e) => e.key === 'Escape' && handle_close()}
			aria-label="Close dialog"
		/>

		<div
			class="tall:h-[90vh] relative h-[80vh] w-[calc(100vw-2.5rem)] transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:mx-auto sm:w-[440px] md:w-[512px]"
		>
			<div class="flex h-full flex-col px-0 pb-4 pt-5 lg:p-6">
				<slot name="close-button" />
				<div class="flex-1 overflow-hidden text-base font-semibold leading-6 text-gray-900">
					<div class="flex-none">
						<h2 id="modal-title">
							<slot name="title" />
						</h2>
						<p id="modal-description" class="text-sm text-gray-500">
							<slot name="description" />
						</p>
					</div>
					<div class="h-full overflow-hidden">
						<div class="mx-auto h-full w-full">
							<slot name="content" />
						</div>
					</div>
					<div class="mt-4">
						<slot name="buttons" />
					</div>
				</div>
			</div>
		</div>
	</div>
</dialog>

<style lang="postcss">
	dialog {
		&::backdrop {
			display: none;
		}
	}

	dialog[open] {
		display: grid;
		place-items: center;
	}

	@media (max-height: 900px) {
		:global(.tall\:h-\[90vh\]) {
			height: 90vh;
		}
	}

	/* Fixes the intentional 100vh bug in iOS Webkit */
	/* .webkit-fix {
		max-height: 100vh;
		max-height: 100svh;
	}*/
</style>
